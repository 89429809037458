<template>
  <div class="px-5" id="page-sendout-email">
    <h5 class="mb-6 ml-2">{{$t('PAGES.SENDOUT.EMAIL_SENDOUT')}}</h5>

    <!--Begin:: template selection-->
    <b-card class="mb-8">
      <template #header>
        <p class="h5 mb-1">
          <span :class="`svg-icon svg-icon-sm mr-2 text-${local_item.template_id ? 'success' : 'danger'}`">
            <inline-svg
              :src="local_item.template_id ? '/assets/svg/check-circle.svg' : '/assets/svg/x-circle.svg'"
            ></inline-svg>
          </span>
          {{$t('PAGES.SENDOUT.SELECT_TEMPLATE_EMAIL')}}
        </p>
      </template>

      <b-form-group
        id="template_id-group"
        :label="$t('PAGES.SENDOUT.EMAIL_TEMPLATE')"
        label-for="template_id"
      >
        <b-row>
          <b-col cols="8">
            <b-form-select
              id="template_id"
              v-model="local_item.template_id"
              :options="templateOptions"
            ></b-form-select>
          </b-col>
          <b-col cols="4">
            <b-row>
              <b-col class="mb-4" v-if="local_item.template_id">
                <a
                  href="#"
                  style="min-width: 180px;"
                  class="btn btn-primary font-weight-bolder font-size-sm ml-4"
                  @click="select_template($event)"
                  :disabled="isTmpReview"
                  ><i class="menu-icon flaticon-eye" style="font-size: 1.0em;"></i>{{$t('PAGES.SENDOUT.REVIEW')}}</a>

              </b-col>
              <b-col>
                  <a
                  href="#"
                  style="min-width: 180px;"
                  class="btn btn-primary font-weight-bolder font-size-sm ml-4"
                  @click="create_template($event)"
                  ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.SENDOUT.CREATE_NEW_TEMPLATE')}}</a>

              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-alert class="mt-5" v-show="!template_ok" show variant="danger"
          >{{$t('PAGES.SENDOUT.TEMPLATE_MUST_BE_SELECTED')}}</b-alert
        >
      </b-form-group>
    </b-card>



    <b-modal size="lg" scrollable v-model="template_review" hide-footer>
      <div v-html="template_html"></div>
    </b-modal>
    <!--End:: template selection-->


    <b-card class="mb-8">
        <template #header>
            <p class="h5 mb-1">
              <span :class="`svg-icon svg-icon-md mr-2 text-${sender_ok ? 'success' : 'danger'}`">
                <inline-svg
                  :src="sender_ok ? '/assets/svg/check-circle.svg' : '/assets/svg/x-circle.svg'"
                ></inline-svg>
              </span>
              {{$t('PAGES.SENDOUT.SENDOUT_INFO')}}
            </p>
        </template>
        <b-row>
            <b-col>
            <b-row>
                <b-col align="start">
                <label for="sender-name">{{$t('PAGES.SENDOUT.FROM_NAME')}}</label>
                </b-col>
                <b-col align="end">
                <label for="sender-name"
                    >{{
                    128 - (local_item.overrides.from_name ? local_item.overrides.from_name.length : 0)
                    }}
                    {{$t('PAGES.SENDOUT.CHARACTERS_REMAINING')}}</label
                >
                </b-col>
            </b-row>
            <b-form-group>
                <b-form-input
                id="sender-name"
                v-model="local_item.overrides.from_name"
                type="text"
                kvarLength="98"
                />
                <b-form-text id="input-live-help"
                >{{$t('PAGES.SENDOUT.INFO_FROM_NAME')}}</b-form-text
                >
            </b-form-group>
            <EmailWithValidDomain
                ref="reply_to"
                title="Reply-to"
                :isDisabled="false"
                :isRequired="false"
                v-bind:value="local_item.overrides.reply_to" v-on:update:email="reply_to_updated"
              />

              <EmailWithValidDomain
                ref="from_email"
                :title="$t('TEMPLATE.FROM_EMAIL')"
                :isDisabled="false"
                :isRequired="false"
                v-bind:value="local_item.overrides.from_email" v-on:update:email="from_email_updated"
              />
            </b-col>
        </b-row>

        </b-card>

        <b-card class="mb-8">
        <template #header>
            <p class="h5 mb-1">
              <span :class="`svg-icon svg-icon-md mr-2 text-${subject_ok ? 'success' : 'danger'}`">
                <inline-svg
                  :src="subject_ok ? '/assets/svg/check-circle.svg' : '/assets/svg/x-circle.svg'"
                ></inline-svg>
              </span>
            {{$t('PAGES.SENDOUT.SET_SUBJECT')}}
            </p>
        </template>

        <b-row>
          <b-col>
            <b-row>
                <b-col align="start">
                <label for="input-subject">{{$t('PAGES.SENDOUT.SUBJECT')}}</label>
                </b-col>
                <b-col align="end">
                <label for="input-subject"
                    >{{
                    78 - (local_item.overrides.subject ? local_item.overrides.subject.length : 0)
                    }}
                    {{$t('PAGES.SENDOUT.CHARACTERS_REMAINING')}}</label
                >
                </b-col>
            </b-row>
            <b-form-group>
                <b-form-input
                id="input-subject"
                kvarlength="150"
                type="text"
                v-model="local_item.overrides.subject"
                />
                <b-form-text id="input-live-help">{{$t('PAGES.SENDOUT.EMAILSUBJECT')}}</b-form-text>
            </b-form-group>
            </b-col>
        </b-row>

        </b-card>

        

        <b-alert :show="!allOk" :variant="'warning'">{{$t('PAGES.SENDOUT.CORRECT_INFO')}}</b-alert>
        <b-alert :show="allOk" :variant="'success'">{{$t('PAGES.SENDOUT.ALL_CORRECT')}}</b-alert>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { validate_email } from '@/core/services/email';
import EmailWithValidDomain from '@/view/components/EmailWithValidDomain.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'sendout-email',
  props: ['item'],
  emits: ['update:item'],
  components: {
    EmailWithValidDomain
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    sender_ok() {
      return (
        this.local_item.overrides.from_name !== '' &&
        this.local_item.overrides.from_name !== undefined &&
        this.local_item.overrides.from_name !== null &&
        this.check_email(this.local_item.overrides.from_email) !== -1 &&
        this.check_email(this.local_item.overrides.reply_to) !== -1
      );
    },
    subject_ok() {
      return (
        this.local_item.overrides.subject !== '' &&
        this.local_item.overrides.subject !== undefined &&
        this.local_item.overrides.subject !== null
      );
    },
    allOk() {
      return this.sender_ok && this.subject_ok && this.template_ok;
    }
  },
  watch: {
    local_item: {
      deep: true,
      handler(newVal) {
        this.$emit('update:item', newVal);
      }
    },
    'local_item.template_id' (newValue, oldValue) {
      if (newValue && newValue !== '') {
        this.template_ok = true;
        this.get_template(newValue);
      }
      else {
        this.template_ok = false;
      }
    }

  },
  mounted() {
    this.get_valid_emails();
    this.load_templates(false);
    this.local_item = { ...this.item };
  },
  data() {
    return {
      valid_emails: [],
      editing: false,
      creating: false,
      templates: [],
      templateOptions: [],
      template_id: null,
      isTmpReview: false,
      template_html: '',
      template_review: false,
      template_ok: false,
      local_item: {
        prio: 'imp',
        sendout_id: '',
        company_id: '',
        status: '', // todo, list status types
        created_at: '2020-01-01',
        template_id: '',
        query_id: '',
        type: 'email', // todo, list type types
        name: '',
        member_ids: [],
        overrides: {
          subject: '',
          preview: '',
          from_name: '',
          reply_to: null,
          from_email: null
        }
      },
    };
  },
  methods: {
    async get_template(template_id) {
      try {
        const response = await axios.get(`/template/${template_id}`);

        if (response.status === 200) {
          this.template = response.data;
        }

        this.set_overrides();
      }
      catch(err) {
        console.error('error getting template', err);
      }
    },

    set_overrides() {
      if (!(this.local_item.overrides.subject !== '' && this.local_item.overrides.subject.length >= 0)) {
        this.local_item.overrides.subject = this.template.subject;
      }

      this.local_item.overrides.preview = '';

      if (!(this.local_item.overrides.from_name !== '' && this.local_item.overrides.from_name.length >= 0)) {
        this.local_item.overrides.from_name = this.template.from_name;
      }

      if (!(this.local_item.overrides.reply_to !== '' && this.local_item.overrides.reply_to.length >= 0)) {
        this.local_item.overrides.reply_to = this.template.reply_to;
      }

      if (!(this.local_item.overrides.from_email !== '' && this.local_item.overrides.from_email.length >= 0)) {
        this.local_item.overrides.from_email = this.template.from_email;
      }
    },

    from_email_updated(email) {
      this.local_item.overrides.from_email = email;
    },
    reply_to_updated(email) {
      this.local_item.overrides.reply_to = email;
    },

    /**
     * To create new template.
     * template_id = -1
     * editing = 0
     */
    create_template(e) {
      e.preventDefault();

      this.$router.push(`/ml-templates/${this.local_item.sendout_id}/0/-1`);
    },

    check_email(email) {
      if (!email || email.length === 0) {
        return 0;
      }

      return validate_email(email) ? 1 : -1;
    },

    async get_valid_emails() {
      try {
        const response = await axios.get(`/email/valid`);

        if (response.status === 200) {
          this.valid_emails = response.data;

          if (!validate_email(this.local_item.overrides.reply_to) && this.valid_emails.length > 0) {
            this.local_item.overrides.reply_to = this.valid_emails[0].email;
            this.local_item.overrides.from_email = this.valid_emails[0].email;
          }
        }
      }
      catch (err) {
        console.error('unable to get valid emails', err);
      }
    },

    async select_template() {
      if (this.local_item.template_id && this.local_item.template_id !== '') {
        this.isTmpReview = true;

        try {
          const res = await axios.get(`/template/${this.local_item.template_id}`);

          if (res.status === 200) {
            this.template_html = res.data.template;
            this.template_review = true;
          }
        } catch (err) {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_SELECT_TEMPLATE'));
        }
      }
      else {
        this.template_html = '';
      }
    },

    async load_templates(is_sms) {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.templates = res.data;
        }

        this.templates = this.templates.filter(item => item.is_sms == is_sms);

        this.templateOptions = [];

        for (const t of this.templates) {
          this.templateOptions.push({
            value: t.template_id,
            text: t.name
          });
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_LIST'));
      }
    },
  }
};
</script>
