<template>
  <div id="advanced-search-query-editor-modal">

    <ErrorPopup :error="error_popup_message" />

    <b-modal ref="modal-editor" size="xl" hide-footer>

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('ADVANCED_SEARCH.MEMBERS') }}</v-tab>
        <v-tab>{{ $t('ADVANCED_SEARCH.USERS') }}</v-tab>

        <v-tab-item class="py-8 mr-4">

          <AdvancedSearchMemberEditor
            class="px-2"
            ref="advanced-search-member-editor"
            :expand="true"
            :show_save_search="false"
            :search_options="local_search_options"
            source="SENDOUT"
            @on_search_result="on_search_result"
            @on_search_count_result="on_search_count_result"
          />

        </v-tab-item>

        <v-tab-item class="py-8 mr-4">

          <AdvancedSearchUserEditor
            :show_save_search="true"
            :search_options="local_search_options"
            ref="advanced-search-user-editor"
            source="SENDOUT"
            @on_search_result="on_search_result"
            @on_search_count_result="on_search_count_result"
          />

        </v-tab-item>

        <v-tab-item>

        </v-tab-item>
      </v-tabs>

      <b-row>
        <b-col cols="12" >
          <div class="bottom-alignment">

            <div class="big-box-invalid" v-if="selected_count === 0">
              <strong>{{ $t('ADVANCED_SEARCH.NUM_SELECTION', { count: selected_count }) }}</strong>
            </div>

            <div v-else class="big-box-success">
              <strong>{{ $t('ADVANCED_SEARCH.NUM_SELECTION', { count: selected_count }) }}</strong>
            </div>

          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <RightModalSaveAndCloseButtons
            :hide_primary="hide_primary_button"
            :text="$t('COMMON.SELECT')"
            :cancel_text="$t('COMMON.ABORT')"
            :spin="true"
            @clicked="on_submit"
            @close="$emit('cancel')"
            ref="saveButton"
          />
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import AdvancedSearchMemberEditor from '@/view/pages/ml/advanced_search/AdvancedSearchMemberEditor.vue';
import AdvancedSearchUserEditor from '@/view/pages/ml/advanced_search/AdvancedSearchUserEditor.vue';
import AdvancedSearchQueryEditor from '@/view/pages/ml/advanced_search/AdvancedSearchQueryEditor.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {
  name: 'AdvancedSearchQueryEditorModal',
  components: {
    AdvancedSearchQueryEditor,
    ErrorPopup,
    RightModalSaveAndCloseButtons,
    AdvancedSearchMemberEditor,
    AdvancedSearchUserEditor
  },
  emits: ['selected_search_options', 'created', 'updated', 'cancel'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    hide_primary_button() {
      return this.selected_count <= 0;
    }
  },
  props: [ 'search_options' ],
  watch: {
    search_options: {
      deep: true,
      handler(val) {

        if (this.search_options !== null) {
          this.local_search_options = { ...this.search_options }
        }
      }
    }
  },

  async mounted() {
    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }
  },
  methods: {

    on_search_result(result, search_options) {
      this.local_search_options = { ...search_options };
    },

    on_search_count_result(count) {
      this.selected_count = count;
    },

    show() {
      this.$refs['modal-editor'].show();
    },

    hide() {
      this.$refs['modal-editor'].hide();
    },

    query_created(query) {
      this.$emit('created', query);
    },

    query_updated(query) {
      this.$emit('updated', query);
    },

    async on_submit() {
      if (this.selected_count <= 0) {
        this.error_popup_message = 'ERR_NO_MEMBERS_IN_SENDOUT_QUERY';

        this.$nextTick(()=>{ this.error_popup_message = null; });

        this.$refs['saveButton'].stop();

        return;
      }

      this.$emit('selected_search_options', this.local_search_options, this.selected_count);

      this.$refs['saveButton'].stop();

    }

  },
  data() {
    return {
      selected_count: 0,
      error_popup_message: null,
      local_search_options: null,
    };
  },

};
</script>
<style lang="scss" scoped>

.big-box-success {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #33bf33;
  border:1px solid #33bf33;
  border-radius: 8px;
}

.big-box-invalid {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  padding: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #ff5757;
  border:1px solid #ff5757;
  border-radius: 8px;
}

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
