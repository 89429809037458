<template>
  <b-modal
    ref="preview-modal"
    size="lg"
    hide-footer
  >
    <b-card :title="$t('PAGES.SENDOUT.SEND_A_TEST')">
      <b-row>
        <b-col lg="12">
          <b-form-group
            id="input-group-sendout_id"
            :label="$t('PAGES.SENDOUT.SMS_SEND_TO')"
            label-for="input-sendout_id"
          >
            <b-form-input v-model="preview_sms_to" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert class="mt-5" v-show="!template_ok" show variant="danger">{{$t('PAGES.SENDOUT.WARNING_COST')}}</b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-button variant="primary" @click="send_preview_sms">{{$t('PAGES.SENDOUT.PREVIEW_SEND')}}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  name: 'SendoutPreviewSMSModal',
  props: ['item'],
  emits: ['send_preview'],
  data() {
    return {
      preview_sms_to: '',
      template_ok: false,
      local_item: {},
    };
  },
  watch: {
    item: {
      deep: true,
      handler() {
        if (this.item) this.local_item = { ...this.item }
      }
    },
    'local_item.template_id' (newValue, oldValue) {
      if (newValue && newValue !== '') {
        this.template_ok = true;
      }
      else {
        this.template_ok = false;
      }
    }

  },
  methods: {
    show() {
      this.$refs['preview-modal'].show();
    },
    hide() {
      this.$refs['preview-modal'].hide();
    },
    send_preview_sms() {
      this.$emit('send_preview', this.preview_sms_to);
    },
  },
  mounted() {
    if (this.item) {
      this.local_item = {...this.item};
    }
  },
}
</script>
