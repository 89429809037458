<template>
  <b-modal
    ref="preview-modal"
    size="lg"
    hide-footer
  >
    <b-card :title="$t('PAGES.SENDOUT.SEND_A_TEST')">
      <b-row>
        <b-col lg="12">
          <b-form-group
            id="input-group-sendout_id"
            :label="$t('PAGES.SENDOUT.EMAIL_SEND_TO')"
            label-for="input-sendout_id"
          >
            <b-form-input v-model="preview_email_to" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-button variant="primary" @click="send_preview_email"
          >{{$t('PAGES.SENDOUT.PREVIEW_SEND')}}</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  name: 'SendoutPreviewEmailModal',
  emits: ['send_preview'],
  data() {
    return {
      preview_email_to: '',
    };
  },
  methods: {
    show() {
      this.$refs['preview-modal'].show();
    },
    hide() {
      this.$refs['preview-modal'].hide();
    },
    send_preview_email() {
      this.$emit('send_preview', this.preview_email_to);
    },
  },
}
</script>

