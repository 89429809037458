<template>
  <div class="container">

    <b-modal
      ref="sendout-modal"
      body-class="pt-0 px-0"
      size="lg"
      hide-footer
      :title="item && item.sendout_id ? $t('PAGES.SENDOUT.UPDATING') : $t('PAGES.SENDOUT.CREATING')"
    >

      <SendoutEditor
        ref="sendout-editor"
        :item="item"
        @update="on_sendout_update"
        @create="on_sendout_create"
      />

    </b-modal>

  </div>

</template>
<script>
import SendoutEditor from '@/view/pages/ml/sendout/SendoutEditor.vue';

export default {
  name: 'SendoutModal',
  components: {
    SendoutEditor,
  },
  props: ['item'],
  emits: ['create', 'update'],
  methods: {
    show() {
      this.$refs['sendout-modal'].show();
    },

    hide() {
      this.$refs['sendout-modal'].hide();
    },

    on_sendout_create(item) {
      this.$emit('create', item);
    },

    on_sendout_update(item) {
      this.$emit('update', item);
    },
  },
}
</script>
