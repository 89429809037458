<template>
  <b-form-group id="email-group" :label="title" label-for="email">
    <b-form-select
      id="select-email"
      v-model="value_model"
      :options="emails_list"
      :state="email_valid"
    />
    <b-form-invalid-feedback>{{$t('COMPONENT.EMAIL_VALID_DOMAIN.INVALID')}}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import axios from 'axios';
import { validate_email } from '@/core/services/email';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'EmailWithValidDomain',
  props: {
    value: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ toasts ],
  emits: ['update:email'],
  components: {
  },

  data() {
    return {
      emails_list: [],
      email_valid: false
    };
  },

  computed: {
      value_model: {
        get() {
          return this.value
        },
        set(val) {
          this.on_email_changed(val);
        }
      },

  },

  async created() {
    await this.load_emails();
    this.on_email_changed(this.value);
  },

  methods: {

    on_email_changed(value) {
      this.email_valid = this.validate_email(value);
      this.$emit('update:email', value);
    },

    validate_email(value) {
      return this.emails_list.includes(value);
    },

    async load_emails() {
      this.emails_list = [];
      try {
        const res = await axios.get(`/email/valid`);
        if (res.status === 200) {
            this.emails_list = res.data.map(item => (item.email));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPONENT.EMAIL_VALID_DOMAIN.UNABLE_LIST'));
        }
      } catch (err) {
        console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPONENT.EMAIL_VALID_DOMAIN.UNABLE_LIST'));
      } 
    },
  }
};
</script>