<template>
  <v-card
    title=""
    class="mb-2 ml-4 mr-4 px-4 pb-2 edit-sendout-container"
    ref="edit-sendout"
    id="edit-sendout"
    hide-footer
  >
    <div>
        <b-form-group id="input-group-sendout_id" label="ID" label-for="input-sendout_id">
          <b-form-input
            id="input-sendout_id"
            v-model="local_item.sendout_id"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <v-row>
          <v-col md="5">
            <b-form-group
              id="input-group-1"
              :label="$t('PAGES.SENDOUT.NAME_ON_SENDOUT')"
              label-for="input-1"
              class="flex-fill mr-4"
            >
              <b-form-input
                id="input-1"
                v-model="local_item.name"
                type="text"
                :placeholder="$t('PAGES.SENDOUT.NAME_ON_SENDOUT')"
              ></b-form-input>
            </b-form-group>
          </v-col>

          <v-col md="2">
            <b-form-group
              id="input-group-type"
              :label="$t('PAGES.SENDOUT.TYPE')"
              label-for="input-type"
              class="flex-fill mr-4"
            >
              <b-form-select :options="types" v-model="local_item.type"> </b-form-select>
            </b-form-group>
          </v-col>

          <v-col md="5">
            <b-form-group id="input-prio" label="Prioritering" label-for="prio" class="flex-fill">
              <b-form-select v-model="local_item.prio" :options="PRIO_OPTIONS"> </b-form-select>
            </b-form-group>
          </v-col>
        </v-row>

        <SendoutEmail
          v-if="local_item.type === 'email'"
          :item="local_item"
          ref="sendout-email"
          class="mt-8"
          @update:item="on_template_updated"
        />

        <SendoutSMS
          v-if="local_item.type === 'sms'"
          :item="local_item"
          ref="sendout-sms"
          class="mt-8"
          @update:item="on_template_updated"
        />

        <b-card>
        <template #header>
            <p class="h5 mb-1">
              <span :class="`svg-icon svg-icon-md mr-2 text-success'`">
                <inline-svg
                  src="/assets/svg/check-circle.svg"
                ></inline-svg>
              </span>
            {{$t('PAGES.SENDOUT.SCHEDULED_AT')}}
            </p>
        </template>

        <v-row>
          <v-col>
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="field-active" type="checkbox" name="" v-model="active_scheduled_at" @input="toggle_active_schedule_at"/>
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{$t('PAGES.SENDOUT.ACTIVATE_SCHEDULE_AT')}}</span >
            </div>
          </v-col>
        </v-row>

        <div v-if="active_scheduled_at">

          <v-row>
            
            <v-col>
              
              <memlist-datetime-picker
                v-model="local_item.scheduled_at"
              >
              </memlist-datetime-picker>
              
            </v-col>
          </v-row>
          <p class="mt-2">{{ $t('PAGES.SENDOUT.SCHEDULED_AT_HELPTEXT') }}</p>
        </div>
        
        </b-card>

        <div class="p-8">
          <h5 class="mb-1">
            <span :class="`svg-icon svg-icon-sm mr-2 text-${(local_item.num_to_send === 0 || !local_item.num_to_send) ? 'danger' : 'success'}`">
              <inline-svg
                :src="(local_item.num_to_send === 0 || !local_item.num_to_send) ? '/assets/svg/x-circle.svg' : '/assets/svg/check-circle.svg'"
              ></inline-svg>
            </span>
            {{$t('PAGES.SEARCH.MAKE_SELECTION')}}
          </h5>

          <AdvancedSearchEditorModal
            ref="advanced-search-member-editor-modal"
            :search_options="local_item.search_options"
            @selected_search_options="selected_search_options"
            @cancel="()=>{this.$refs['advanced-search-member-editor-modal'].hide();}"
          />

          <b-button
            style="max-width: 200px; margin-top: 16px;"
            @click="open_select_search_modal"
            block
            variant="outline-primary"
          >
            {{ $t('ADVANCED_SEARCH.SELECT_RECIPIENTS', { count: recipients_count }) }}
          </b-button>
        </div>

    </div>
    
    <RightSaveButton
      ref="save-button"
      class="mb-4"
      :text="$t('COMMON.SAVE')"
      @clicked="on_save_sendout"
    />
  </v-card>
</template>

<script>
import AdvancedSearchEditorModal from '@/view/pages/ml/advanced_search/AdvancedSearchEditorModal.vue';
import SendoutEmail from '@/view/pages/ml/sendout/SendoutEmail.vue';
import SendoutSMS from '@/view/pages/ml/sendout/SendoutSMS.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'SendoutEditor',
  components: {
    RightSaveButton,
    SendoutSMS,
    SendoutEmail,
    AdvancedSearchEditorModal
  },
  props: ['item'],
  emits: ['create', 'update'],
  data() {
    return {
      active_scheduled_at: false,
      local_item: {},
      types: [
        { value: 'email', text: this.$t('MEMBER.EMAIL') },
        { value: 'sms', text: 'SMS' }
      ],
      PRIO_OPTIONS: {
        sys: this.$t('PAGES.SENDOUT.SYS'),
        imp: this.$t('PAGES.SENDOUT.IMP'),
        def: this.$t('PAGES.SENDOUT.DEF')
      },
    }
  },
  computed: {
    recipients_count() {
      return this.local_item.num_to_send || 0;
    }
  },
  
  watch: {
    
    'local_item.scheduled_at'() {
      if (this.local_item.scheduled_at) {
        this.active_scheduled_at = true;
      }
    }
  },
  methods: {
    toggle_active_schedule_at() {
      console.log(this.active_scheduled_at)

      const target = !this.active_scheduled_at;

      if (!target) {
        this.local_item.scheduled_at = null;
      }
    },
    open_select_search_modal() {
      this.$refs['advanced-search-member-editor-modal'].show();
    },

    selected_search_options(search_options, count) {
      this.local_item.search_options = search_options;
      this.local_item.num_to_send = count;

      this.$refs['advanced-search-member-editor-modal'].hide();
    },

    on_save_sendout() {
      if (this.local_item.sendout_id) {
        this.$emit('update', this.local_item);
      }
      else {
        this.$emit('create', this.local_item);
      }
    },
    on_item_updated(item) {
      this.local_item = { ...item };
    },
    on_template_updated(data) {
      this.local_item.template_id = data.template_id;
    }
  },
  mounted() {
    if (this.item) {
      this.local_item = {...this.item};
    }
  },
}
</script>
