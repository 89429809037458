<template>
  <div class="no-padding">
    <div style="height: 100%;">
      <b-form ref="form" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ADVANCED_SEARCH.QUERY_NAME')">

              <b-form-input v-model="local_item.name" />

            </b-form-group>
          </b-col>
        </b-row>

        <RightModalSaveAndCloseButtons
          class="mr-16"
          ref="save-button"
          :spin="true"
          :text="$t('COMMON.SAVE')"
          @clicked="save_clicked()"
          @close="$emit('cancel')"
        />
      </b-form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'AdvancedSearchQueryEditor',

  props: ['item','search_options'],
  emits: ['cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true,
      handler(val) {
        if (this.item) {
          this.local_item = { ...this.item }
        }
        else {
          this.local_item = {}
        }
      }
    },

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'sid']),

  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }
    else {
      this.local_item = {}
    }
  },

  methods: {

    save_clicked() {},

    async create_query(search_options) {
      try {
        const res = await axios.post(`/search_query/${this.currentCompanyId}`, { search_options });

        if (res.status === 201) {
          this.search_queries.push(res.data);
          this.toastr('danger', this.$t('COMMON.OK'), this.$t('ADVANCED_SEARCH.QUERY_CREATED'));
          return;
        }

      }
      catch (err) {
        console.error('create_query', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_QUERY_CREATE'));
    },

    async update_query(query) {
      try {
        const res = await axios.put(`/search_query/${query.id}`, { ...query });

        if (res.status === 204) {
          this.toastr('danger', this.$t('COMMON.OK'), this.$t('ADVANCED_SEARCH.QUERY_UPDATED'));
          return;
        }

      }
      catch (err) {
        console.error('update_query', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_UPDATE_QUERY'));
    },


  },


  data() {
    return {

      local_item: null,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
